export const getTranslations = t => {
  return {
    search: t("kf.pdp.label.searchBtn"),
    signIn: t("kf.loggedin.button.signIn"),
    or: t("kf.global.label.or"),
    createCartAccount: t("kf.cart.label.createCartAccount"),
    saveYourCart: t("kf.cart.label.saveYourCart"),
    needHelpOrder: t("kf.cart.label.needHelpOrder"),
    callSupport: t("kf.cart.label.callSupport"),
  }
}
