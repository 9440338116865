export const addAnalyticsEditCart = cartPage => {
  const { adobeDataLayer: {} = {} } = window
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const productInfo = page?.cart?.item?.map(i => i?.productInfo)

  const eventInfo = {
    eventAction: "checkout:edit cart",
    eventName: "checkout:edit cart",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "edit cart",
    internalLinkPosition: "checkout",
    internalLinkType: "checkout:navigation",
    internalLinkZoneName: "cart:landing page:order summary",
    internalLinkURL: cartPage,
    clickInternalLinks: "true",
    internalLinkZoneName: "checkout:header:navigation",
  }
  pushAnalyticsObjToDataLayer(eventInfo, productInfo)
}
export const addAnalyticsCallSupportCheckout = (contactNumber, source) => {
  const { adobeDataLayer: {} = {} } = window
  let eventInfo = {
    clickInternalLinks: "true",
    eventAction: "checkout:need help with your order:sales team number",
    eventName: "checkout:need help with your order:sales team number",
    eventMsg: "n/a",
    eventStatus: "success",
    eventType: "help support",
    internalLinkName: contactNumber,
    internalLinkPosition: "help support",
    internalLinkType: "checkout:call supports",
    internalLinkURL: `tel:${contactNumber.replace(/Kohler India:/g, "")}`,
    internalLinkZoneName: "checkout:need help with your order",
  }
  if (source === "cart") {
    eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${source}:landing page:main content area:call support`,
      eventName: `${source}:landing page:main content area:call support`,
      eventType: `call support`,
      internalLinkName: contactNumber,
      internalLinkPosition: `${source}`,
      internalLinkType: `${source}:call support`,
      internalLinkURL: `tel:${contactNumber}`,
      internalLinkZoneName: `${source}:landing page:main content area`,
    }
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const continueCheckoutEventInfo = checkoutUrl => {
  const { adobeDataLayer: {} = {} } = window
  const eventInfo = {
    eventAction: "cart:landing page:checkout",
    eventName: "cart:landing page:checkout",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "checkout",
    internalLinkPosition: "cart",
    internalLinkType: "cart:navigation",
    internalLinkZoneName: "cart:landing page:order summary",
    internalLinkURL: checkoutUrl
      ? (checkoutUrl.indexOf("http") === -1 ? window.location.origin : "") +
        checkoutUrl
      : "n/a",
    clickInternalLinks: "true",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

export const pushAnalyticsObjToDataLayer = (
  eventInfo = {},
  productInfo = []
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const event = "cmp:click"
  if (productInfo.length > 0) page["productInfo"] = productInfo
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
  })
}
