import React from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { addAnalyticsCallSupportCheckout, pushAnalyticsObjToDataLayer } from "./analytics"


const CartInfo = ({ i18n = {}, supportLine = [] }) => {
  const { isAuth } = useSelector(selectAuthState)
  const dispatch = useDispatch()
  const onClickSignIn = () => {
    if (!isAuth) {
      pushAnalyticsObjToDataLayer({"eventAction":"cart:landing page:sign in","eventName":"cart:landing page:sign in","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"sign in","internalLinkPosition":"cart","internalLinkType":"cart:navigation","internalLinkZoneName":"cart:landing page:order summary","internalLinkURL":"n/a","clickInternalLinks":"true"})
      dispatch(setAuthModalVisibility({ show: true }))
    }
  }
  const onClickSignUp = () => {
    pushAnalyticsObjToDataLayer({"eventAction":"cart:landing page:create an account","eventName":"cart:landing page:create an account","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"create an account","internalLinkPosition":"cart","internalLinkType":"cart:navigation","internalLinkZoneName":"cart:landing page:order summary","internalLinkURL":"n/a","clickInternalLinks":"true"})
    dispatch(setAuthModalVisibility({ show: true, active: 1 }))
  }
  return (
    <div className="cart-info">
      <>
        {!isAuth && (
          <p className="cart-info__desc">
            <span
              className="gbh-data-layer"
              tabIndex="0"
              role="link"
              onClick={onClickSignIn}
            >
              {`${i18n.signIn} `}
            </span>
            {`${i18n.or} `}
            <span
              className="gbh-data-layer"
              tabIndex="0"
              role="link"
              onClick={onClickSignUp}
            >
              {i18n.createCartAccount}
            </span>
            {` ${i18n.saveYourCart}`}
          </p>
        )}
        <p className="cart-info__title">
          <span>
            {" "}
            {`${i18n.needHelpOrder}`} {`${i18n.callSupport} `}{" "}
          </span>
          <a 
            className="cart-info__link" 
            href={`tel:${supportLine}`} 
            onClick={() => addAnalyticsCallSupportCheckout(supportLine, "cart")}
          >
            {supportLine}
          </a>
        </p>
      </>
    </div>
  )
}

export default CartInfo
