import {
  FREE_MSG,
  NAVIGATION,
  ADD_SERVICE,
  TOILET_INSTALL,
  INSTALLATION_SERVICES,
  INSTALL_SERVCIE,
  COMPLIMENTARY,
  ADD_TO_CART,
} from "@/components/Shared/UpsellInstallServices/analytics/constant"
import { SUCCESS } from "@/constants/index"
import { pushAnalyticsObjToDataLayer } from "@/utils/helper"

const getProductInfoForAnalytics = (cartProductData = {}) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const { site: { siteName = "n/a" } = {} } = page
  const brand = siteName.toLowerCase()
  const {
    desc = "",
    discountedPrice = "",
    price = "",
    globalOffer = "n/a",
    globalDiscount = 0,
    name: productTileName = "",
    category = "",
    discountPriceState = "regular price ",
    unitPrice = "n/a",
    color: productColor = "n/a",
    productCoupondiscount = 0,
    productCouponCode = "n/a",
    customerFacingSKU = "n/a",
    productPrice = 0,
    quantity = 0,
    image = "n/a",
  } = cartProductData

  const productInfo = {
    description: desc,
    frequency: "n/a",
    globalPromotionPrice:
      discountedPrice > 0 ? Number((price - 0).toFixed(2)) : 0,
    globalOffer: globalOffer,
    globalDiscount: Number(globalDiscount.toFixed(2)),
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState: discountPriceState,
    productBasePrice: Number(unitPrice),
    productCategory: category,
    productColor,
    productCoupondiscount,
    productCouponCode,
    productID: customerFacingSKU,
    productName: productTileName || desc || category || "n/a",
    productPartnerBuyNow: "n/a",
    productRoom: "service" || "n/a",
    productSalePrice: Number(productPrice),
    productSaleable: true,
    productStatus: "in stock",
    productSuperSku: false,
    productTileName,
    quantity,
    defaultImageName: image,
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    productFindingMethod: "n/a",
    productCollectionsName: category,
    productBrand: brand,
    itemType: "service" || "n/a",
  }
  return productInfo
}

const addAnalyticsForToolTip = (servicename, title, url, cartProductData) => {
  const services = servicename.toLowerCase()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const statusEvent = title == "add to cart" ? SUCCESS : "n/a"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products:${ADD_SERVICE}:${title}`,
    eventMsg: "n/a",
    eventName: `${pageload}:${ADD_SERVICE}:${title}`,
    eventStatus: statusEvent,
    eventType: `teaser`,
    internalLinkName: title,
    internalLinkPosition: `${pageload}:teaser`,
    internalLinkType: `${pageload}:${services}`,
    internalLinkURL: url ? url : "n/a",
    internalLinkZoneName: `${pageload}:${ADD_SERVICE}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo, cartProductData)
}
const addAnalyticsForheader = (zipcode, title, isServiceAvailable) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const titleName = title.toLowerCase()
  const internalLink = isServiceAvailable ? ADD_SERVICE : COMPLIMENTARY
  if (titleName === "change zip code") {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `upsell products:${FREE_MSG}:${titleName}`,
      eventMsg: "n/a",
      eventName: `${pageload}:${FREE_MSG}:${titleName}`,
      eventStatus: "n/a",
      eventType: NAVIGATION,
      internalLinkName: titleName,
      internalLinkPosition: `${pageload}:${NAVIGATION}`,
      internalLinkType: `${pageload}:${zipcode}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${pageload}:${internalLink}`,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  } else {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `upsell products :${INSTALLATION_SERVICES}:${INSTALL_SERVCIE}:check availability`,
      eventMsg: "n/a",
      eventName: `${pageload}:${INSTALL_SERVCIE}:check availability`,
      eventStatus: SUCCESS,
      eventType: NAVIGATION,
      internalLinkName: "search",
      internalLinkPosition: `${pageload}:find ${INSTALLATION_SERVICES}`,
      internalLinkType: `${pageload}:${zipcode}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${pageload}:${TOILET_INSTALL}`,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  }
}

const addAnalyticsForFreeServices = (title, url) => {
  const titleInfo = title.toLowerCase()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products:${FREE_MSG}: ${titleInfo}`,
    eventMsg: "n/a",
    eventName: `${pageload}:${FREE_MSG}:${titleInfo} `,
    eventStatus: "n/a",
    eventType: "teaser",
    internalLinkName: titleInfo,
    internalLinkPosition: `${pageload}:teaser`,
    internalLinkType: `${pageload}:${COMPLIMENTARY}`,
    internalLinkURL: url ? url : "n/a",
    internalLinkZoneName: `${pageload}:${COMPLIMENTARY}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
const getrequiredProductInfo = (
  productinfo = {},
  childindex = -1,
  index = -1,
  persona = "",
  qty = 1,
  isParent = ""
) => {
  const {
    ProductDescriptionProductShort_s: decs = "",
    [`priceList.${persona}.discountedPrice_d`]: discountedPrice = "0",
    CustomerFacingBrand_s: brandName = "",
    Product_Category: productCategory = "",
    RegionBrandName_s: Name = "",
    availableQuantity = 0,
    sku_s: skuid = "",
    discountPriceState = "regular price ",
    productCoupondiscount = 0,
    productCouponCode = "n/a",
    ["productImages.url_ss"]: productImage = [],
    [`priceList.${persona}.finalPrice_d`]: baseprice = 0,
    masterSKU_s: mastersku = "",
    unitPrice: unitPrice = "",
    productParentImage: productParentImage = "",
    productColor: productColor = "default",
    parentglobalPromotionPrice = "",
    itemType = "",
    productRoomType = "",
    productPrice = "",
  } = productinfo
  const pageInfoDetails = window?.adobeDataLayer?.getState("page")
  const isPdp =
    pageInfoDetails?.pageInfo?.contentType &&
    pageInfoDetails?.pageInfo?.contentType === "pdp"
  const skuProductId = skuid ?? mastersku ?? ""
  const productId =
    "k" === skuProductId[0]?.toLowerCase()
      ? `${skuProductId}`
      : `K-${skuProductId}`

  const productInfo = {
    description: decs.toLowerCase(),
    frequency: "n/a",
    globalPromotionPrice: parentglobalPromotionPrice
      ? parentglobalPromotionPrice
      : discountedPrice > 0
      ? Number((baseprice - 0)?.toFixed(2))
      : 0,
    globalOffer: "n/a",
    globalDiscount: discountedPrice,
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState: discountPriceState,
    productBasePrice: unitPrice || baseprice,
    productCategory: productCategory.toLowerCase(),
    productColor: productColor.toLowerCase(),
    productCoupondiscount,
    productCouponCode,
    productID: productId,
    productName:
      Name.toLowerCase() ||
      decs.toLowerCase() ||
      productCategory.toLowerCase() ||
      "n/a",
    productPartnerBuyNow: "n/a",
    productRoom: productRoomType ? productRoomType.toLowerCase() : "service",
    productSalePrice: productPrice || unitPrice || baseprice,
    productSaleable: true,
    productStatus: availableQuantity ? "in stock" : "out of stock",
    productSuperSku: false,
    productTileName: Name.toLowerCase() || "n/a",
    quantity: isPdp ? 1 : qty,
    defaultImageName: productParentImage || productImage[0],
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    productFindingMethod: "n/a",
    productCollectionsName: productCategory.toLowerCase(),
    productBrand: brandName.toLowerCase(),
    itemType: itemType ? itemType.toLowerCase() : "service",
    category: productCategory.toLowerCase(),
  }
  if (index > -1 && childindex > -1) {
    productInfo.productRecommendationType = `${isParent ? "parent" : "child"}|${
      index + 1
    }(${productinfo.foundIndex ? productinfo.foundIndex + 1 : childindex + 1})`
  }
  return productInfo
}

export const addAnalyticsForADDtoCart = (
  productinfo = {},
  servicechecked = "",
  servicedata = {},
  persona = "GST",
  serviceQTY = 1,
  subTotal = 0
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload = "cart"

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `cart preview: cart update: ${ADD_TO_CART}`,
    eventMsg: "n/a",
    eventName: `cart preview: cart update: ${ADD_TO_CART}`,
    eventStatus: "n/a",
    eventType: "naviagtion",
    internalLinkName: `add to cart`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${ADD_TO_CART}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:preview`,
    upsellCartValue: subTotal,
  }

  const arr = productinfo.map(item => {
    const { childindex = -1, index = -1, totalQTY = 1 } = item
    return getrequiredProductInfo(item, childindex, index, persona, totalQTY)
  })

  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click", arr)
}

export const addAnalyticsForIncludeforRequiredItem = (
  checked = false,
  included = "",
  recommendationTitle = "",
  installServiceData = {},
  index = 1,
  childindex = -1,
  persona,
  isParent = false,
  qty = 1
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page?.category?.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const isPdp = page?.category?.pageType

  const parentIndex = isPdp ? 0 : index
  const includetitle = included?.toLowerCase()
  const recommendation = recommendationTitle?.toLowerCase()
  const check = checked ? "select" : "remove"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${isPdp}:upsell product:${recommendation}: ${includetitle} ${check}`,
    eventMsg: "n/a",
    eventName: `${isPdp}:upsell product:${recommendation}: ${includetitle} ${check}`,
    eventStatus: "n/a",
    eventType: "teaser",
    internalLinkName: `${includetitle}: ${check}`,
    internalLinkPosition: `${isPdp ? isPdp : `${pageload}:`}:upsell`,
    internalLinkType: `${isPdp ? isPdp : `${pageload}:`}:${includetitle}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${isPdp ? isPdp : `${pageload}:`}:${recommendation}`,
  }
  const serviceinfo = getrequiredProductInfo(
    installServiceData,
    childindex,
    parentIndex,
    persona,
    qty,
    isParent
  )
  pushAnalyticsObjToDataLayer(eventInfo, serviceinfo)
}

const addAnalyticsForArrows = (
  label = "",
  upsellData = [],
  index,
  persona,
  serviceAvailableTitleUpSell,
  recommendationTitle
) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const type = label == "Next" ? "right" : "left"
  const title = upsellData?.requiredItems?.length
    ? recommendationTitle.toLowerCase()
    : serviceAvailableTitleUpSell.toLowerCase()
  const productInfoforCart = []
  const productInfoforRequiredItems = []
  const firstVisibleItem = upsellData ? upsellData : {}
  const {
    cartProductData = {},
    requiredItems = [],
    installServiceData = {},
  } = firstVisibleItem
  let installServiceInfo = ""
  if (!_isEmpty(installServiceData)) {
    installServiceInfo = {
      ["ProductDescriptionProductShort_s"]:
        installServiceData?.narrativeDescription,
      ["Product_Category"]: installServiceData?.productCategory,
      image: installServiceData?.productImageUrl,
      unitPrice: installServiceData?.price,
      customerFacingSKU: installServiceData?.customerFacingSKU,
      productTileName: installServiceData?.name,
      ["CustomerFacingBrand_s"]: installServiceData?.customerBrand,
      availableQuantity: installServiceData?.quantity,
      sku_s: installServiceData?.customerFacingSKU,
      productParentImage: `${installServiceData?.swatchUrl}${installServiceData?.images}?$SellModalDesktop$`,
      parentglobalPromotionPrice: installServiceData?.discountedPrice,
      productPrice: installServiceData?.discountedPrice,
    }
  }

  const requiredDataWithInstallServiceInfo = [
    ...(installServiceInfo ? [installServiceInfo] : []),
    ...requiredItems,
  ]

  const cartProductInfo =
    getProductInfoForAnalytics(
      cartProductData,
      index,
      requiredDataWithInstallServiceInfo.length
    ) ?? {}

  productInfoforCart.push(cartProductInfo)
  const requiredProductInfo = requiredDataWithInstallServiceInfo.map(
    (item, childindex) =>
      getrequiredProductInfo(item, childindex, index, persona) ?? []
  )
  productInfoforRequiredItems.push(requiredProductInfo)

  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products: ${title}: ${type} arrow`,
    eventMsg: "n/a",
    eventName: `${pageload}:${title}: ${type} arrow`,
    eventStatus: "n/a",
    eventType: "carousel",
    internalLinkName: `${type} arrow`,
    internalLinkPosition: `${pageload}:upsell`,
    internalLinkType: `${pageload}:${title}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:${title}`,
  }

  const productInfoItems = productInfoforCart.concat(
    productInfoforRequiredItems.flat(2)
  )
  pushAnalyticsObjToDataLayer(eventInfo, productInfoItems)
}

export {
  addAnalyticsForToolTip,
  addAnalyticsForheader,
  addAnalyticsForFreeServices,
  addAnalyticsForArrows,
}
