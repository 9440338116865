import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectAuthState } from "@/store/features/authSlice"
import { selectCartState, addToCart, setCart } from "@/store/features/cartSlice"
import {
  showPreviewCart,
  setConsolidatedZipCodes,
  selectGenericState,
  getUserProfile,
} from "@/store/features/genericSlice"
import UpSellInstallServicesCarousalSlick from "@/components/Shared/UpsellInstallServices/InstallServiceCarousalSlick"
import {
  getUserPersona,
  calculateCartData,
  sanitizeDOMString,
  getUpSellAuthData,
  getProcessedProductInfoBasedOnLineItems,
} from "@/utils/helper"
import {
  getProductDataFromSkuIds,
  getZipCodes,
  getProductBySkus,
} from "@/components/Shared/UpsellInstallServices/api"
import { getProcessedInstallServiceData } from "@/components/Shared/UpsellInstallServices/utils"
import {
  locationState,
  setUtilityLocation,
} from "@/store/features/locationSlice"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import Input from "@/components/core/Input/Input"
import styles from "@/components/Shared/UpsellInstallServices/index.module.scss"
import { useTranslation } from "next-i18next"
import { getTranslations } from "@/components/Shared/UpsellInstallServices/localization"
import {
  addAnalyticsForheader,
  addAnalyticsForFreeServices,
} from "@/components/Shared/UpsellInstallServices/analytics"

import { WAIT_TIME_FOR_LEAR_MORE } from "@/components/Shared/UpsellInstallServices/analytics/constant"
import ProductCardForUpsell from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell"
import { getProductInventory } from "@/components/Shared/UpsellInstallServices/api"
import { getListOfRequiredSkus } from "@/components/Shared/UpsellInstallServices/api"
import {
  addToCart as addToCartFunc,
  attachWarehouseData,
} from "@/components/ProductDetail/v3/AddToCart/utils"
import { getConfig } from "@/constants/config"
import { getETACached } from "@/utils/cart"
import CartInfo from "@/components/cart/CartInfo"

const UpSellInstallServices = ({
  authData = {},
  isRequireToLookupFullData = false,
  upSellProductData = [],
  occupyFullWidth = false,
}) => {
  const learnMoreRef = useRef(null)
  const slickRef = useRef(null)
  const { cart } = useSelector(selectCartState)
  const { isAuth, user: { firstName = "" } = {} } = useSelector(selectAuthState)
  const { utilityLocation = "" } = useSelector(locationState)
  const { consolidatedZipCodes = [] } = useSelector(selectGenericState)
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)
  const { search = "Search" } = staticText
  const dispatch = useDispatch()
  const [upSellData, setUpSellData] = useState([])
  const [showUpSellModule, setShowUpSellModule] = useState(false)
  const [zipCodeVal, setZipCodeVal] = useState(utilityLocation)
  const [showInput, setShowInput] = useState(utilityLocation ? false : true)
  const [isServiceAvailable, setIsServiceAvailable] = useState(true)
  const [loading, setLoading] = useState(true)
  const [isUpsellDataAvailable, setIsUpsellDatAvailable] = useState(false)
  const [isDataAvailToProceed, setIsDataAvailToProceed] = useState(false)
  const [currencySign, setCurrencySign] = useState("")
  const [inventoryUpdate, setInventoryUpdate] = useState(0)

  useEffect(() => {
    if (utilityLocation) setZipCodeVal(utilityLocation)
  }, [utilityLocation])

  const upsellHeaderCLickHandler = e => {
    e.preventDefault()
    const { target: { href = "", textContent = "" } = {} } = e
    addAnalyticsForFreeServices(textContent.toLowerCase(), href)
    window.open(href)
  }
  const {
    headerAuthData: {
      userTitleUpSell = "",
      zipLabelUpSell = "",
      zipChangeLabelUpSell = "",
    } = {},
    productCard: productCardAuthData = {},
    upSellServiceData: {
      presetConfigsUpSell: presetConfigs = "",
      swatchUrlUpSell: swatchUrl = "",
      jsonFilePathUpSell = "",
      isForPostPurchase = false,
      recommendationTitle = "",
      selectedTitle = "",
      serviceAvailableTitleUpSell = "",
      supportContactLabel = "1-800-103-2244",
    } = {},
  } = getUpSellAuthData(authData, isRequireToLookupFullData)
  useEffect(() => {
    let upSellHeaderElement = null
    setTimeout(() => {
      if (learnMoreRef?.current) {
        upSellHeaderElement = learnMoreRef?.current?.querySelector("a")
        upSellHeaderElement?.addEventListener("click", upsellHeaderCLickHandler)
      }
    }, WAIT_TIME_FOR_LEAR_MORE)
    if (!zipCodeVal) setZipCodeVal(localStorage?.getItem("currentZipCode"))
    return () => {
      upSellHeaderElement?.removeEventListener(
        "click",
        upsellHeaderCLickHandler
      )
    }
  }, [])

  useEffect(() => {
    if (!_isEmpty(cart) && !isForPostPurchase) {
      const { lineItems = [] } = cart
      if (lineItems.length) {
        fetchSkusBasedOnLineItems()
      } else {
        setIsDataAvailToProceed(false)
        setLoading(false)
      }
    }
  }, [cart?.lineItems?.length, isAuth])

  useEffect(() => {
    if (isForPostPurchase) {
      processWithPostPurchaseData()
    } else {
      if (isRequireToLookupFullData) {
        setShowUpSellModule(false)
      }
    }
  }, [isForPostPurchase])

  const processWithPostPurchaseData = async () => {
    if (upSellProductData.length) {
      const {
        productItems = [],
        brandName,
        currency,
      } = await getProcessedProductInfoBasedOnLineItems(upSellProductData)

      mapProductDataWithServicesData(productItems, brandName, currency)
    }

    return null
  }

  const fetchSkusBasedOnLineItems = async () => {
    calculateCartData({ cart: sanitizeDOMString(cart) }, true).then(res => {
      const {
        cartItems,
        brandName,
        cart: { price: { currency = currencySign } = {} } = {},
      } = res
      mapProductDataWithServicesData(cartItems, brandName, currency)
    })
  }
  useEffect(() => {
    getConfig().then(config => {
      const currency = _get(config, "internationalization.currencySign", "")
      setCurrencySign(currency)
    })
  }, [])

  const [pincode, setPincode] = useState("")
  const getCookieValue = name =>
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || ""

  const getUserPincode = () => {
    dispatch(getUserProfile())
      .unwrap()
      .then(res => {
        let userPincode = ""
        const addresses = res.addresses ?? []
        if (addresses[0]) {
          userPincode = addresses[0].postalCode ?? ""
        }
        setPincode(userPincode)
      })
  }

  useEffect(() => {
    const availablePincode = getCookieValue("pincode")
    if (availablePincode) {
      setPincode(availablePincode)
    } else if (isAuth) {
      getUserPincode()
    }
  }, [isAuth])

  const [warehouse, setWarehouses] = useState()

  const mapProductDataWithServicesData = async (
    productItems = [],
    lwAppName = "",
    currency = currencySign
  ) => {
    if (!productItems.length) {
      setIsDataAvailToProceed(false)
      return
    }
    const persona = getUserPersona()
    const productCartItems = productItems.filter(
      item => item?.category !== "Install Services"
    )
    const installServicesSkus = []
    productCartItems.forEach(item => {
      const { associatedServices = [] } = item
      if (associatedServices.length) {
        installServicesSkus.push(...associatedServices)
      }
    })

    const productAndServiceData = productCartItems.map(item => ({
      cartProductData: item,
    }))

    const parentSkus = productAndServiceData.map(
      item => item?.cartProductData?.sku
    )

    const recommendations = await getProductBySkus(
      parentSkus,
      "sku_s,CrossSellRecommendationSKUList_ss"
    )

    const listOfRequiredSkus = recommendations
      .filter(item => item.CrossSellRecommendationSKUList_ss)
      .map(item => ({
        parentSku: item.sku_s,
        requiredSkus: item.CrossSellRecommendationSKUList_ss,
      }))

    const requiredSkus = listOfRequiredSkus
      .map(item => item.requiredSkus)
      .flat(1)
    // Executing second API...
    const { data = {} } =
      (await getProductDataFromSkuIds(persona, requiredSkus, lwAppName)) ?? {}

    const skuidString = requiredSkus.join(",")

    if (!_isEmpty(data) && skuidString) {
      const { response: { docs: requiredSkusProductData = [] } = {} } = data

      const { data: { results: productInventory = [] } = {} } =
        (await getProductInventory(skuidString)) ?? {}

      if (requiredSkusProductData.length) {
        for (const item of listOfRequiredSkus) {
          const index = parentSkus.indexOf(item.parentSku)
          if (index > -1) {
            const requiredItemsProductData = requiredSkusProductData.filter(
              productItem => item.requiredSkus?.includes(productItem.sku_s)
            )
            requiredItemsProductData.forEach(async (requiredProItem, index) => {
              const payload = {
                pincode,
                qty: 1,
                mastervariantkey:
                  productAndServiceData.length &&
                  productAndServiceData[0]?.cartProductData?.masterVariantKey,
                sku: requiredProItem.sku_s,
                type: "Sales",
              }
              let etaData = await getETACached(false, payload, false)
              const { result: { data: { warehouse = [] } = {} } = {} } = etaData

              requiredItemsProductData[index].warehouse = warehouse
              requiredItemsProductData[index].availableQuantity =
                warehouse?.reduce(
                  (total, warehouse) => total + warehouse.availableQty,
                  0
                ) || 0
              setInventoryUpdate(prevCount => prevCount + 1)
            })
            productAndServiceData[index].requiredItems =
              requiredItemsProductData
          }
        }
      }
    }

    const filteredProductAndServiceData = productAndServiceData.filter(
      item => item?.requiredItems?.length || !_isEmpty(item?.installServiceData)
    )
    setIsUpsellDatAvailable(filteredProductAndServiceData.length)
    setUpSellData(filteredProductAndServiceData)
    setLoading(false)
    setIsDataAvailToProceed(true)
    setUtilityInfo()
  }

  const addServiceToCart = async items => {
    addToCartFunc(items, false, "").catch(err => {
      console.log(err, "err")
    })
  }

  const handleAddToCart = (item, serviceQuantity) => {
    const {
      installServiceData: { skuId = "", customerBrand = "", quantity = 1 } = {},
    } = item

    addServiceToCart([
      {
        sku: skuId,
        quantity: _isEmpty(serviceQuantity) ? quantity : serviceQuantity[skuId],
        brand: customerBrand,
      },
    ])
  }

  const setUtilityInfo = (showModule = false) => {
    setIsServiceAvailable(showModule)
    setShowInput(false)
    dispatch(setUtilityLocation({ utilityLocation: zipCodeVal }))
    localStorage.setItem("currentZipCode", zipCodeVal)
  }

  if (!loading && isDataAvailToProceed && upSellData?.length) {
    return (
      <div className={styles.upsellmodal}>
        <div
          className={`upsell-modal ${
            isForPostPurchase && !occupyFullWidth
              ? "upsellmodal-order-history"
              : ""
          }`}
        >
          <div className="upsell-modal-header">
            <div className="upsell-modal-header__title">{userTitleUpSell}</div>
          </div>

          {isUpsellDataAvailable && upSellData?.length > 0 ? (
            <UpSellInstallServicesCarousalSlick
              upSellData={upSellData}
              authData={getUpSellAuthData(authData, isRequireToLookupFullData)}
              handleAddToCart={handleAddToCart}
              slickRef={slickRef}
              currency={currencySign}
              addServiceToCart={addServiceToCart}
              inventoryUpdate={inventoryUpdate}
            />
          ) : null}
          <CartInfo i18n={staticText} supportLine={supportContactLabel} />
        </div>
      </div>
    )
  }
  // else return <ProductCardForUpsell authData={productCardAuthData} />
  return (
    <div className={styles.upsellmodal}>
      <div className="upsell-modal">
        <CartInfo i18n={staticText} supportLine={supportContactLabel} />
      </div>
    </div>
  )
}

export default UpSellInstallServices
